/* ./src/index.css */
@tailwind base;

html {
  @apply bg-gray-50;
}

body {
  @apply bg-gray-50 text-gray-800 font-sans;
}

h1 {
  @apply font-black text-4xl;
}
h2 {
  @apply font-extrabold text-2xl;
}
h3 {
  @apply font-bold text-xl;
}
h4 {
  @apply font-bold text-lg;
}

@tailwind components;
@tailwind utilities;
